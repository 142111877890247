<template>
  <div class="h-full monthpicker__wrapper">
    <transition name="slide-fade" mode="out-in">
      <table v-if="monthsList.length > 0">
        <tbody>
          <tr v-for="i in [0, 1, 2, 3]" :key="i">
            <td v-for="(data, index) in monthsList.slice(i * 3, i * 3 + 3)" :key="index">
              <button class="w-full h-12 rounded hover_month_bg"  @click="selectedMonthHandler(data)" :class="selectedMonth === data ? 'selected_month text-white' : ''">{{data}}</button>
            </td>
          </tr>
        </tbody>
      </table>
    </transition>
  </div>
</template>
<script>
export default {
  data () {
    return {
      selectedMonth: '',
      selectedMonthYear: '',
      selectedMonthTemp: '',
      monthsList: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    }
  },
  props: ['currentDate'],
  mounted () {
    this.selectedMonth = this.monthsList[new Date(this.currentDate).getMonth()]
    this.selectedMonthTemp = this.monthsList[new Date(this.currentDate).getMonth()]
    this.selectedMonthYear = new Date(this.currentDate).getFullYear()
  },
  watch: {
    currentDate: {
      handler () {
        if (this.selectedMonthYear !== this.currentDate.getFullYear()) {
          this.selectedMonth = ''
        } else {
          this.selectedMonth = this.selectedMonthTemp
        }
        // let currentYear = new Date().getFullYear()
        // let changeYear = this.currentDate.getFullYear()
        this.monthsList = []
        setTimeout(() => {
          this.monthsList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        }, 200)
      },
      deep: true
    }
  },
  methods: {
    selectedMonthHandler (data) {
      this.selectedMonth = data
      let index = this.monthsList.findIndex(val => {
        return val === data
      })
      this.$emit('selectedMonth', index)
    }
  }
}
</script>
<style scoped>
button {
  outline: none;
  border: none;
  font-size: 17px;
  font-family: 'Roboto-Regular';
  background-color: white;
}
.monthpicker__wrapper {
  width: 266px;
}
table {
  height: 100%;
  width: 100%;
}
td{
  width: calc(266/3);
}
button:active {
  border: none !important;
}
.selected_month {
    background-color: #0000FF;
}
.hover_month_bg:hover {
    background-color: #f0f0f0;
}
</style>
