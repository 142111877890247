<template>
  <div class="text-text1">
    <div>
         <div  class="mt-2" v-if="selectedItemList.length > 0">
            <p class=" text-gray4 heading-4 font-bold mb-4">Items list:</p>
            <div class="px-4 cursor-pointer hover:bg-gray1 border-b py-1" v-for="(data, index) in selectedItemList" :key="index + 'key'" @click="itemSelect = true">
            <div class="text-text2 text-sm flex mb-1 font-bold justify-between">
                <p class="pr-4" >{{data.itemName}}</p>
                <p class="" >{{data.itemAmount | amountOnlyNumber}}</p>
            </div>
            <div class="text-text2 flex">
                <p class="w-32 sm:w-72 heading-7">{{data.itemPrice | amountOnlyNumber}}</p>
                <span class="w-32 sm:w-72 heading-7">Qty:{{data.itemQty}} <span class="pl-4" v-if="data.isTaxable">Taxed</span></span>
            </div>
            <div class="text-text2 flex">
                <p class="heading-7 text-gray4">{{data.itemDescription.substr(0, 100)}} <span v-if="data.itemDescription.length > 100">...</span></p>
            </div>
            </div>
        </div>
    </div>
    <div class="">
        <div class="mt-2 py-2 heading-5">
            <span v-if="selectedItemList.length > 0" @click="itemSelect = true" class="text-primary font-bold mt-2 cursor-pointer">+ Add more Item</span>
            <span v-else  @click="itemSelect = true" class="text-primary font-bold mt-2 cursor-pointer">+ Add Item to this Job</span>
        </div>
    </div>
        <ItemSelection :itemsSelected="selectedItemList" v-if="itemSelect" />
  </div>
</template>
<script>
import ItemSelection from '@/View/setting/items/components/ItemSelection.vue'
export default {
  name: 'jobitme',
  components: {
    ItemSelection,
  },
  data () {
    return {
      itemSelect: false,
      selectedItemList: [],
    }
  },
  props: [],
   created() {
  },
  mounted () {
    this.$root.$on("getUnscheduleObject", (response) => {
      console.log(response, "getUnscheduleObject");
      this.selectedItemList = response.itemList
      this.setItems()
    });
    this.$root.$on("itemSelectionHandler", (response, data) => {
      console.log(response, data);
      if (response && data.length > 0 && this.itemSelect) {
        this.selectedItemList = data
        this.setItems()
      }
        this.itemSelect = false
        
    });
  },
  beforeDestroy () {
    this.$root.$off('getUnscheduleObject')
    this.$root.$off('itemSelectionHandler')
    // this.$root.$off('itemSelectDetailHandler')
  },
  watch: {},
  methods: {
    setItems () {
       this.$root.$emit('setItemSelectedHandler', this.selectedItemList) 
    }
  },
}
</script>
