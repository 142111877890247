<template>
  <div>
      <div class="timepicker_wraper bg-gray1 card p-3 rounded-xl">
          <div>
            <div class="flex items-center justify-between">
                <p class="text-text1 font-semibold heading-4">{{title}}</p>
                <div class="text-right" style="width: 120px; font-weight: 700;">
                    <span v-if="hourInputValue === '00'">12:</span>
                    <span  v-else>{{hourInputValue | hourFormater}}:</span>
                    <span v-if="minuteInputValue > 0">{{minuteInputValue}}</span>
                    <span v-else>00</span>
                    <span id="1" v-if="hourInputValue === '00' && minuteInputValue === 0"> PM</span>
                    <span id="2" v-if="hourInputValue === '00' && minuteInputValue > 0"> AM</span>
                    <span id="3" v-if="hourInputValue < 12 && hourInputValue != '00'"> AM</span>
                    <span id="4" v-if="hourInputValue >= 12 && minuteInputValue > 0"> PM</span>
                    <span id="5" v-if="hourInputValue > 12 && minuteInputValue === 0"> PM</span>
                    <span id="6" v-if="hourInputValue === 12 && minuteInputValue === 0"> AM</span>
                </div>
            </div>
            <div class="flex flex-wrap gap-4">
                <div class="text-left gap-2 pt-1 pb-1 pl-2 flex items-center">
                    <div class=""><span>Hour:</span></div>
                    <div class="">
                        <button  class="timePick_btn"
                        @click="decreaseHours()"  small>
                        <span>-</span>
                        </button>
                    </div>
                    <input  @focus="$event.target.select()" class="minuteBox cust_text_field pl-3 w-12 border focus:border-primary rounded flext items-center justify-center px-1 py-1 " v-model="hourInputValue"  v-mask="'##'">
                    <div class="ml-1">
                      <button  class="timePick_btn"
                        @click="increaseHours()"  small>+
                    </button>
                    </div>
                </div>
                <div class="text-left pl-2 items-center flex gap-2">
                  <div class=""><span>Min:</span></div>
                  <button v-for="(min) in minutesArr" :key="min"
                    :class="min === minuteInputValue ? 'timePick_btn' : ' timePick_btn2 '"
                    @click="seMinutes(min)" :outlined="min !== minuteInputValue" small>{{min}}
                  </button>
                </div>
            </div>
                        <!-- <i @click="clearTime()" class="fas fa-times primary--text ml-1 c-pointer"></i> -->
          </div>
      </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      hourInputValue: '0',
      minuteInputValue: '00',
      minutesArr: [0, 15, 30, 45],
      selectedValues: {
        hour: '0',
        minutes: '00'
      }
    }
  },
  props: ['value', 'title'],
  model: {
    prop: 'value',
    event: 'input'
  },
  mounted () {
    if (this.value !== undefined && this.value !== '' && this.value !== null) {
      let x = this.value.split(':')
      this.hourInputValue = x[0]
      console.log('cccc', x[1]);
      this.minuteInputValue = parseInt(x[1])
    }
  },
  watch: {
    hourInputValue: {
      handler () {
        if (this.hourInputValue >= 0) {
          if (this.hourInputValue > 23) {
            this.hourInputValue = 23
          }
          if (this.hourInputValue === 0) {
            this.hourInputValue = '00'
          }
          if (this.hourInputValue === '') {
            this.hourInputValue = ''
          }
        }
        this.setTime()
      },
      deep: true
    }
  },
  methods: {
    seMinutes (minutes) {
      switch (minutes) {
        case 15:
          this.minuteInputValue = minutes
          break
        case 30:
          this.minuteInputValue = minutes
          break
        case 45:
          this.minuteInputValue = minutes
          break
        case 0:
          this.minuteInputValue = minutes
          break
        default:
          this.minuteInputValue = 0
      }
      this.setTime()
    },
    setTime () {
      let hrValue = String(this.hourInputValue)
      let minValue = String(this.minuteInputValue)
      if (hrValue.length === 1) {
        hrValue = '0' + hrValue
      }
      if (minValue.length === 1) {
        minValue = '0' + minValue
      }
      this.$emit('input', `${hrValue === '' ? '00' : hrValue}:${minValue}:00`)
      console.log('set time call')
    },
    decreaseHours () {
      if (this.hourInputValue === '00') {
        this.hourInputValue = 23
      } else {
        this.hourInputValue = Number(this.hourInputValue) - 1
      }
    },
    increaseHours () {
      if (this.hourInputValue === 23) {
        this.hourInputValue = '00'
      } else {
        this.hourInputValue = Number(this.hourInputValue) + 1
      }
    },
    isNumber (KeyboardEvent) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      const keyPressed = KeyboardEvent.key
      if (!keysAllowed.includes(keyPressed)) {
        KeyboardEvent.preventDefault()
      }
    }
  }
}
</script>
<style scoped>
.timepicker_wraper {
  font-family: 'Roboto-Regular' !important;
  /* max-width: 290px !important; */
  /* width: 290px !important; */
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(92, 91, 91, 0.2);
}
.minBtnBox {
  margin-left: 2px;
}
.minsBtn {
  font-size: 25px !important;
  height: 28px!important;
  min-width: 13px!important;
  padding: 5px 8px!important;
  /* box-shadow: none !important; */
}
.plusBtn {
  font-size: 19px !important;
  height: 28px!important;
  min-width: 13px!important;
  padding: 5px 8.5px!important;
  box-shadow: none !important;
}
.minBtn {
  margin: 2px !important;
  height: 28px !important;
  min-width: 13px !important;
  padding: 5px 5px !important;
}
.button-color {
    background-color: #1976D2;
}

.outlineselected {
    outline: 1px solid #1976D2;
    border-radius: 2px;
}
.outline {
    outline: 1px solid black;
    border-radius: 2px;
}
</style>
