<template>
  <div id="yearBodyPicker" class="overflow-y-scroll mt-1 text-center yearpicker_body__wrapper">
    <div class="py-1 px-0 hover_year_bg"
      v-for="data in yearsList"
      :key="data"
      @click="selectedYearHandler(data)"
      :class="selectedyear === data ? 'year_selected' : ''">
      {{data}}
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      yearsList: [],
      selectedyear: 0
    }
  },
  props: ['currentDate'],
  created () {
    let currentYear = new Date(this.currentDate).getFullYear()
    this.generateYearsList(currentYear)
    this.selectedyear = currentYear
  },
  mounted () {
    let element = document.getElementsByClassName('year_selected')
    document.getElementById('yearBodyPicker').scrollTo({top: element[0].offsetTop - 150})
  },
  methods: {
    generateYearsList (currentYear) {
      for (let year = currentYear - 50; year < currentYear + 50; year++) {
        this.yearsList.push(year)
      }
    },
    selectedYearHandler (year) {
      this.selectedyear = year
      this.$emit('yearSelected', year)
    }
  }
}
</script>
<style scoped>
.yearpicker_body__wrapper {
  height: 300px;
  font-family: 'Roboto-Regular';
}
.year_selected {
  color: blue;
  font-size: 25px;
}
.hover_year_bg:hover {
    background-color: #f0f0f0;
}
</style>
